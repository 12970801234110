<template>
  <b-card>
    <div v-if="!load" class="row-height">
      <div class="col-md-12 pr-0">
        <div class="row justify-space-between p-0">
          <div class="col-md-9">
            <h2 class="text-purple font-weight-bold mb-1">
              {{ $t("upload_additional_DD_docs") }} 👋
            </h2>
          </div>
          <div class="col-md-3" align="end">
            <b-button
              align="end"
              v-if="!addNewItem"
              @click="addNewCard"
              variant="primary"
              >{{ $t("add_new") }}</b-button
            >
          </div>
        </div>
        <b-alert
          v-if="message"
          v-model.trim="showDismissibleAlert"
          variant="primary"
          dismissible
          class="mb-0 p-1"
        >
          <div class="alert-body" v-html="message"></div>
        </b-alert>

        <br /><br />

        <div class="row col-md-12" v-if="!showPdfViwer">
          <div
            class="responsive-width invoice-documents-cards"
            v-if="pdfs.length > 0"
            v-for="(item, index) in pdfs"
            :key="index"
          >
            <b-card
              id="cards"
              class="text-center upload-card"
              footer-class="text-muted"
            >
              <template #footer>
                <!-- <h6 class="mb-1 pointer web" @click="readFile(item)">
                  {{ $t("read_files") }}
                </h6> -->
                <h6 class="mb-0 light-blue pointer web" @click="readFile(item)">
                  {{ $t("download") }}
                </h6>
                <h6
                  class="mb-0 light-blue pointer mobile"
                  @click="downloadFile(item)"
                >
                  {{ $t("download") }}
                </h6>
              </template>
              <b-img
                src="/images/file.svg"
                v-bind="mainProps"
                fluid
                alt="Responsive image"
              ></b-img>
              <br />
              <br />
              <br />

              <b-card-text v-if="!item.title">
                {{ $t("upload_a_file_or_drag_files_here") }}
              </b-card-text>

              <b-card-text v-else>
                <feather-icon icon="PaperclipIcon" class="pointer" size="15" />
                {{ item.title.substring(0, 12) }}
                <span v-if="item.title.length > 12">...</span> <br />
                <span v-if="item.description">
                  {{ item.description.substring(0, 12) }}
                </span>
                <div v-else style="height: 23px"></div>
              </b-card-text>

              <b-form-file
                v-if="!hideInputs"
                @change="uploadFile($event, 'old')"
                v-model.trim="file1"
                :placeholder="$t('upload_a_file_or_drag_files_here')"
                drop-placeholder="Drop file here..."
                ref="fileInput1"
              ></b-form-file>

              <feather-icon
                v-else
                class="light-green"
                icon="CheckCircleIcon"
                variant="success"
                size="26"
              />
            </b-card>
          </div>
          <div
            v-if="addNewItem"
            class="responsive-width invoice-documents-cards"
          >
            <b-card class="text-center upload-card" footer-class="text-muted">
              <template #footer>
                <b-form-group class="text-left mb-1" id="description">
                  <b-form-input
                    :placeholder="$t('description')"
                    v-model.trim="desc"
                  ></b-form-input>
                </b-form-group>
                <br />
                <b-button class="mb-0" variant="primary" @click="addFile()">
                  {{ $t("submit") }}
                </b-button>
              </template>
              <b-img
                src="/images/upload-file.svg"
                v-bind="mainProps"
                fluid
                alt="Responsive image"
              ></b-img>

              <br v-if="$i18n.locale == 'en'" />
              <b-card-text style="margin-top: 9px" class="font-11">
                {{ $t("upload_a_file_or_drag_files_here") }}
              </b-card-text>
              <b-form-file
                class="pt-1"
                @change="uploadFile($event, 'new')"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                ref="invoiceFile"
                v-model.trim="file2"
              ></b-form-file>
              <div></div>
            </b-card>
          </div>
        </div>
        <div class="row" v-if="false">
          <div class="col-md-6">
            <Table
              :showFilter="false"
              hover
              :fields="fields"
              :url="'additional-docs/list'"
            >
              <template #cell(id)="data">
                {{ data.item.title }}
              </template>
              <template #cell(custom)="data">
                <b-button
                  class="mt-1 mr-1"
                  @click="downloadFile(data.item)"
                  variant="outline-success"
                  size="sm"
                >
                  <FeatherIcon icon="DownloadIcon"></FeatherIcon>
                </b-button>
                <b-button
                  class="mt-1"
                  @click="readFile(data.item)"
                  variant="outline-success"
                  size="sm"
                >
                  <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
                </b-button>
                <br />
              </template>
            </Table>
          </div>
          <div class="col-md-6" style="background: #bcbcbc">
            <embed
              v-if="!viewDocumentLoader"
              class="mt-1"
              :src="src"
              :type="embedType"
              width="100%"
              id="embed-pdf"
            />
            <div v-else>
              <img src="/new-loader.svg" />
            </div>
          </div>
        </div>
        <b-row
          m-0
          p-0
          class="col-md-12 row"
          v-if="pdfs.length > 12"
          align="center"
        >
          <b-col cols="12" class="text-center pt-2">
            <b-pagination
              @input="getData()"
              class="justify-content-center"
              v-model.trim="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="cards"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
      <br />
      <br />
    </div>
    <div v-else>
      <img src="/new-loader.svg" />
    </div>
    <!-- <div class="col-md-12 text-aling-end" aling="end">
      <b-button
        variant="success"
        @click="showSuccessMessage"
        class="text-end col-md-1"
        >{{ $t("save") }}
      </b-button>
    </div> -->
  </b-card>
</template>
<script>
var fileDownload = require("js-file-download");
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
import Table from "@/components/common/Table.vue";
export default {
  components: { Table },
  data() {
    return {
      file1: null,
      file2: null,
      file3: null,
      pdfs: [],
      file4: "",
      newFile: null,
      files: [],
      file1Title: "",
      file2Title: "",
      file3Title: "",
      hideInputs: false,
      showPdfViwer: false,
      newDocs: [],
      addNewItem: false,
      showDismissibleAlert: true,
      load: false,
      viewDocumentLoader: false,
      showDesc: false,
      base64: "",
      type: "",

      desc: "",
      fileEvent: "",
      mainProps: {
        blank: false,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      perPage: 10,
      totalItems: 0,
      currentPage: 1,
      message: "",
      src: "",
      embedType: "",
      fields: [
        {
          text: "reference",
          label: "reference",
          align: "start",
          key: "id",
          sortable: true,
        },
        { label: "Title", customSlot: true, key: "custom", class: "" },
      ],
    };
  },
  watch: {
    file1(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.pdfs.push(newValue.name);
        }
      }
    },
    sideBar(newValue, oldValue) {
      this.showPdfViwer = false;
    },
  },
  created() {
    this.getData();
    this.showcomp();
  },
  computed: {
    sideBar() {
      return this.$store.state.app.sidebar;
    },
    rows() {
      return this.totalItems;
    },
  },

  methods: {
    getData() {
      this.load = true;
      this.pdfs = [];
      this.$http
        .post("/additional-docs/list?page=" + this.currentPage)
        .then((res) => {
          if (
            typeof res.data.value.data != "undefined" &&
            res.data.value.data.length > 0
          ) {
            this.totalItems = res.data.value.total;
            res.data.value.data.forEach((el) => {
              if (el.visible != 0) {
                this.pdfs.push(el);
              }
            });

            this.currentPage = res.data.value.current_page;
            this.hideInputs = true;
            if (this.pdfs.length < 1) {
              this.addNewCard();
            }
          } else {
            this.addNewCard();
          }

          this.load = false;
        });
    },
    readFile(item) {
      this.viewDocumentLoader = true;
      this.$store.commit("app/ON_SIDEBAR_CLICK", false);
      this.$http
        .post(
          "/additional-docs/read/" + item.id,
          {},
          {
            responseType: "json",
          }
        )
        .then((response) => {
          this.type = "";
          this.base64 = "";
          (this.src = ""), (this.embedType = "");
          this.base64 = response.data.value.b64;
          this.type = response.data.value.ext;

          if (this.type == "pdf") {
            this.src =
              "data:application/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "application/" + this.type;

            // this.showPdfViwer = true;
          } else {
            this.src =
              "data:image/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "image/" + this.type;
            this.viewDocumentLoader = false;

            // this.showPdfViwer = true;
          }

          this.$store.commit("app/SET_FILE_TYPE", this.type);
          this.$store.commit("app/SET_FILE_BASE64", this.base64);

          window.open("/read-files", "_blank");
          // src="files/Brochure.pdf" type="application/pdf"
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    downloadFile(item) {
      this.$store.commit("app/ON_SIDEBAR_CLICK", false);
      this.$http
        .post(
          "/additional-docs/download/" + item.id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, item.title);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    addNewCard() {
      this.addNewItem = true;
    },
    showcomp() {
      this.$http
        .post(`/company/show/${this.$store.state.auth.user.company_id}`)
        .then((res) => {
          if (res.data) {
            this.message = res.data.value.document_message.replace(
              /(?:\r\n|\r|\n)/g,
              "<br>"
            );
          }
        });
    },

    showAddNewSuccessMessage() {
      let text = this.$t("document_added_successfully");
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    showSuccessMessage() {
      let text = this.$t("your_work_has_been_saved");
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((res) => {
        this.$router.push("/");
      });
    },
    uploadFile(event, type) {
      this.fileEvent = event;
      this.type = type;
    },

    addFile() {
      if (this.fileEvent) {
        this.load = true;
        let formData = new FormData();
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        formData.append("pdf", this.fileEvent.target.files[0]);
        formData.append("description", this.desc);
        this.$http
          .post("/additional-docs/store", formData, config)
          .then((res) => {
            if (res.data.status != 500) {
              this.$store.commit("verticalMenu/SHOW_DOCS_SIDE_BAR", true);
              document.getElementById("updateSideBar").click();
              if (this.type == "new") {
                this.addNewItem = false;
                this.getData();
                this.showAddNewSuccessMessage();
              } else {
                this.showSuccessMessage();
              }
              this.load = false;
            } else {
              let text = this.$t(
                res.data.value && res.data.value.pdf && res.data.value.pdf[0]
                  ? res.data.value.pdf[0]
                  : "Something went wrong, please try again"
              );
              this.$swal({
                position: "center",
                icon: "error",
                title: text,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: "btn btn-secondary",
                },
                buttonsStyling: false,
              });
              this.load = false;
            }
          });

        this.desc = "";
        this.fileEvent = "";
        this.file1 = null;
        this.file2 = null;
      }
    },
  },
};
</script>
<style>
</style>
